.dashboard {
  // display: flex;
  // flex-direction: column;
  padding: 1.5em 1em;
}

  .chart-grid {

    .grid-item {
      // border-radius: 10px;
      border: none;
      padding: 1em;
      background-color: #161616;
    }

    .chart {
      width: 100%;
      padding: 0px;
      border-radius: 0.5em;
    }
  }
// }

.adv-terminal {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  // flex-grow: 1;
  // border: 1px solid #2d2d3d;
  // border-radius: 10px;
  background-color: #161616;

  .terminal-header {
    font-weight: lighter;
    color: #FDEFD3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #2e2e2e;

    h2 {
      padding-left: 2.5%;
      font-weight: lighter;
      font-size: 24px;

      @media (max-width: 768px) {
        // h2 {
          font-size: 20px; /* Smaller font size for small screens */
        // }
      }
    }
  }
}
