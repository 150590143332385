.bar-chart-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #161616;
  height: 100%;
  width: 100%;
  border-radius: 0.5em;

  .title {
    padding: 1em;
    color: white;
    font-size: 1em;
  }
}