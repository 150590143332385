.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .MuiDataGrid-root {
    font-weight: normal;
    font-size: 14px;
    .MuiDataGrid-columnHeaders {
      color: #ffffff76;
      font-weight: normal;
    }

    .MuiDataGrid-cell {
      color: #bbbbbb;
      text-align: left;
      padding-left: 2.5%;
      display: flex;
      align-items: center;
      gap: 8px; // Adjust based on your design
    }

    .MuiDataGrid-row {
      &:hover {
        background-color: #1b1b28; // Adjust hover background color
      }
    }

    .MuiDataGrid-columnHeader, .MuiDataGrid-cell {
      &:first-of-type {
        cursor: pointer;
        transition: transform 0.3s ease;
        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }
}





// .grid-item {
//   padding: 2em;
//   border: 1px solid #2d2d3d;
//   // border-radius: 10px;

//   .alpha-info {
//     padding-left: 2em;
//     display: flex;
//     flex-direction: column;

//     h4 {
//       color: #bbbbbb;
//     }
//   }

//   /* alpha.scss */
//   .expanded-content {
//     overflow: hidden;
//     transition: max-height 0.3s ease;
//   }

//   .hidden-content {
//     max-height: 0;
//   }

//   .shown-content {
//     max-height: 500px; /* Adjust as needed */
//   }

//   // .alpha-table {

//   // }
// }

// .body-row {
//   transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth transition for both transform and box-shadow
//   // will-change: transform, box-shadow; // Optimize for animations
//   position: relative; // Ensure transform is applied correctly
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); // Initial subtle shadow


//   &:hover {
//     transform: translateY(-3px); // Move up slightly on hover
//     // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // Increased shadow to give "floating" effect
//     cursor: pointer;
//   }
// }
// .table-container {
//     overflow-x: auto; // Enable horizontal scrolling
//     -webkit-overflow-scrolling: touch; // Smooth scrolling on touch devices
//   }
  
//   .alpha-table {
//     width: 100%;
//     border-collapse: collapse;
//     font-weight: normal;
//     font-size: 14px;
//     min-width: 500px; // Minimum width of the table, adjust as needed
//     // background-color: #1C1C28;
//     border-collapse: separate; /* This allows spacing between rows */
//     border-spacing: 0 10px; /* This adds vertical spacing between rows */
//     padding: 0px 20px 0px 20px;
  
//     .testing {
//       color: white;
//     }
  
//     tr {
  
//       th {
//         font-weight: normal;
//         // color: rgb(112, 112, 112);
//         height: 4vh;
//         color: #ffffff76;
//       }
//     }
    
//     .body-row {
//       height: 6vh;
//       // background-color: #60ffff; /* Light grey background on hover */
//       background-color: #242424;
//       transition: transform 1s ease;
  
//       :hover {
//         cursor: pointer;
//       }
  
//     }
  
//     td,
//     th {
//       text-align: left;
//       padding-left: 2.5%;
  
//     }
  
//     td {
//       color: #bbbbbb;
  
//       .icon-text-container {
//         display: inline-flex; // Use inline-flex for the container of the icon and text
//         align-items: center; // This will vertically center the icon and text within the container
//         gap: 8px; // Adjust the gap between the icon and text as needed
//       }

//       .contains-image {
//         display: flex;
//         gap: 0.5em;
//         align-items: center;


//         .logo {
//             height: 1.5em;
//             width: 1.5em;
//             max-height: 100%;
//             border-radius: 100px;
//         }

//         .ape {
//             height: 18px;
//             width: 18px;
//             max-height: 100%;
//         }


//       }

//     }
//   }
