.form-starter {
  // padding: 30px;
  // overflow -y: auto; /* Allows vertical scrolling if content overflows */
  /* Optional: manage horizontal overflow as well, if needed */
  overflow-x: hidden; /* Hides horizontal scrollbar, adjust as needed */


  h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    color: #a4a4a9;
    margin-bottom: 15px;
  }

  button {
    margin-top: 40px;
    color: #fdefd3;
    background-color: #fdefd342;
    border: 0px solid black;
    height: 40px;
    width: 100%;
    margin-bottom: 40px;
    border-radius: 8px;
    // padding: 55px 20px 25px 20px;
  }
}

.question-form {
  // width: 80%;
  // border: white 2px solid;
    padding: 0px 30px;



  .questions {
    
    height: 35vh;
    margin: 0;
    overflow-y: auto; /* Allows vertical scrolling if content overflows */

    .question {
      display: flex;
      flex-direction: column;
      padding: 10px 5px;

      label {
        color: #bbbbbb;
        margin-bottom: 10px;
      }

      .select {
        height: 5vh;
        color: white;
        border-radius: 10px;
        .MuiOutlinedInput-notchedOutline {
          border: solid 1px #2e2e2e;
        }

        .MuiSvgIcon-root {
          color: #2e2e2e;
        }
      }

      .textfield {

        .MuiInputLabel-root {
          font-family: "IBM Plex Mono", monospace;
          // font-size: 1;
        }

        .MuiOutlinedInput-root {
          height: 6vh;
          background-color: #1f1f1f;
          font-family: "IBM Plex Mono", monospace;
          font-size: 14px;

          fieldset {
            border-color: #2e2e2e !important;
          }

          &:hover fieldset {
            border-color: #2d2d3d !important;
          }

          &.Mui-focused fieldset {
            border-color: #2e2e2e !important;
          }
        }

        // Targeting the text color within the TextField
        .MuiInputBase-input {
          color: white; // Sets the font color
          &::placeholder {
            // Targets the placeholder text
            color: rgb(71, 71, 71); // Sets the placeholder text color to white
            opacity: 1; // Ensures the placeholder text is fully visible
          }
        }
      }
    }
  }

  .container {
    padding: 20px 0px;
    border-top: solid #2e2e2e 1px;

  

    .switch-container {
      display: flex;        /* Use flexbox layout */
      align-items: center;  /* Align items vertically in the center */
      justify-content: space-between; /* Place content at the space-between */
      width: 100%;          /* Take full width of the container */


      .tooltip-typography {
        color: gray;
        font-size: 12px;
        text-decoration: underline; /* Adds underline to indicate hoverability */
        text-decoration-style: dashed; // Changes the underline to be dashed
        font-family: "IBM Plex Mono", monospace;

        cursor: pointer; /* Changes cursor to a pointer on hover to indicate interactivity */
      }
    }

    .button-container {
      display: flex;
      flex-direction: row; /* This is the default direction, so it's optional */
      justify-content: space-between; /* Adjusts the spacing between buttons */
      align-items: center; /* Centers the buttons vertically, optional based on your design */

      .form-button {
        // margin-top: 40px;
        color: #fdefd3;
        background-color: #fdefd342;
        border: 0px solid black;
        height: 50px;
        width: 100%;
        // margin-bottom: 30px;
        border-radius: 8px;
        // padding: 25px 10px 25px 10px;
        font-family: "IBM Plex Mono", monospace;

      }
    }
  }
}
