.line-chart-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background-color: #161616;
  height: 100%;
  border-radius: 0.5em;
  padding: 0 1em;

  // .line-glow {
  //   filter: drop-shadow(0 15px 5px rgb(255, 255, 255, 1));
  // }

  .title {
    padding: 1em;
    color: white;
    font-size: 1em;
  }
}
