.navigation-tabs {
  margin-top: 5px;
  margin-bottom: 8px;
}

button {
  background-color: black;
  color: white;
  border: 0px solid black;
  cursor: pointer;
  outline: none;
}

button.selected {
  text-decoration: underline;
  text-decoration-color: rgb(194, 194, 194);
}
