.header-and-cats {
  font-size: small;
  // border: solid 1px #2d2d3d;
  border-radius: 10px;
  margin-bottom: 3vh;
  padding-left: 10px;
  background-color: #212130;

  .information {
    width: 100%;
    margin: 0px;

    .grid-item {
      border: none;
      padding: 0px;


      .cat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 3vh;
        color: white;
        // border: solid 1px #2d2d3d;
        padding: 15px;
        font-size: 10px;
        border-radius: 10px;

        .puffer-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .cat-header {
          color: rgb(163, 163, 163);
          font-size: 10px;
          margin-bottom: 0.5vh;
          // align-items: left;
        }

        .icon-text-container {
          display: inline-flex; // Use inline-flex for the container of the icon and text
          align-items: center; // This will vertically center the icon and text within the container
          gap: 10px; // Adjust the gap between the icon and text as needed

          .menu-icon {
            font-size: 100%;
          }

          .supply-tag {
            display: flex;
            align-items: center;
            background-color: #182b48; /* Adjust color to match your design */
            color: #0081fb;
            padding: 4px 10px;
            border-radius: 20px; /* This creates the pill shape */
            font-size: 12px;
            gap: 6px; /* Adjust as necessary */
          }
        }

        a {
          text-decoration: none;
          color: inherit; // Keeps the link the same color as the surrounding text
          &:hover,
          &:visited,
          &:active {
            color: inherit; // Keeps the link the same color on hover, visited, and active
          }

          .link-with-icon {
            display: inline-flex; /* Align items in a row */
            align-items: center; /* Center items vertically */
            gap: 4px; /* Optional: add some space between the text and the icon */
          }
        }
      }
    }
  }
}
