.mobile-menu {
    border-bottom: 1px solid #2d2d3d;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between; // Added to align items on opposite ends
    padding: 0 10px; // Add some padding on both sides

  img {
    width: 10vh;
  }

  Menu {
    border-radius: 0px;
    
  }
}
