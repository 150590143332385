.header-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 1em;
  
  .grid-item {
    // flex-grow: 1; // This allows each item to grow and fill the container
    display: flex;
    flex-direction: column;
    justify-content: center; // Center content vertically within each grid item
    gap: 0.5em;
    border-radius: 0.25em;
    padding: 1em 2em;
    background-color: #161616;
    color: white;
    
    span {
      color: #8d8d8d;
      font-size: 0.75em;
    }

    h2 {
      color: white;
      font-weight: 400;
      font-size: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5em;
    }
    
    .logo-entry {
      display: flex;
      gap: 1em;

      img {
        width: 2em;
        height: 2em;
        border-radius: 50%;
      }
    }
  }


}