.navbar-dropdown {
  // width: 100%;
  // height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #dropdown-menu {
    padding: 0em 1.55em;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    margin-bottom: 0.25em;

    // Default dropdown link
    .dropdown-link {
      color: #6e6c6c; 
      font-size: 0.8em;

      cursor: pointer;
      &:hover {
        color: white;
      }
    }

    // When it is the active link
    .dropdown-link.active {
      color: #fdefd3;
      font-weight: 600;
    }
  }
}