@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,700&display=swap");

body {
  background-color: black;
}

.app-container {
  height: 100vh;
  background-color: black;
  font-family: "IBM Plex Mono", monospace;
  margin: 0;
  // display: flex; // This makes it a flex container
  // flex-direction: row; // Align children in a row
}

.main-content {
  margin-left: 250px; // Ensure this is at least the width of your navbar
  // flex-grow: 1; // This allows the main content to grow and fill the available space
  // height: 100vh; // Optional, depending on your design needs

  @media (max-width: 900px) {
    margin-left: 0;
  }
}
