.about {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 5em;

    // Maybe if we want to have the logo on this page?
    // img {
    //     width: 25%;
    // }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75%;
        margin-top: 3em;
        padding-bottom: 2em;
        border-bottom: 1px solid #1fcfcf;
        
        h1 {
            font-weight: lighter;
            color: #1fcfcf;
            // border-bottom: 1px solid #1fcfcf;
            border: 1px solid #1fcfcf;
            padding: 0.5em;
        }

        p {
            font-style: italic;
            margin-top: 2.5em;
            color: white;
        }
    }

}