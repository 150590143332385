.overview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5em 1em;
  gap: 1em;

  // .top-data {
  //   height: 325px;
  // }
  .tab-section {
    width: 100%;
    border-bottom: 1px solid #181818;
  }
}