.stat-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  color: #fff;
  padding: 10px;
}

.stat {
  flex: 1;
}

.stat-name {
  font-weight: bold;
  font-size: 12px;
  color: #777777;
}

.stat-value {
  margin-top: 5px;
  color: "white";
  font-size: 15px;
}

.health-bar {
  width: 100px;
  height: 20px;
  border: 1px solid #fff;
  position: relative;
  margin-top: 5px;
}

.health-bar-fill {
  height: 100%;
  background-color: #00ff00; /* Green color for healthy */
  transition: width 0.5s ease; /* Add animation */
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
