.navbar {
  display: flex;
  flex: 0 0 250px; // This means don't grow, don't shrink, start at 250px width
  flex-direction: column;
  justify-content: space-between; // This will push your logos to the bottom
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;

  @media (max-width: 900px) {
    width: 80vw;
  }

  // background-color: #191923;
  border-right: 1px solid #2e2e2e;

  .menu-toggle-checkbox {
    display: none; /* Hide the actual checkbox */
  }

  .menu-toggle-label {
    display: none; /* Only show this on small screens */
    /* Style to look like a hamburger menu */
  }

  .menu-toggle-label span {
    /* Hamburger icon bars */
  }

  // @media (max-width: 1200px) {
  //   .menu-toggle-label {
  //     display: block; /* Show the toggle on small screens */
  //   }

  //   .pages {
  //     display: none; /* Hide the navigation links by default */
  //   }

  //   .menu-toggle-checkbox:checked + .pages {
  //     display: block; /* Show the navigation links when checkbox is checked */
  //   }
  // }

  .pages {
    display: flex;
    flex-direction: column; // Stack links vertically
    justify-content: flex-start;
    padding: 10.5%;

    // Normal entries (not menu)
    a {
      cursor: default;
      text-decoration: none;
      width: 100%;
      
      img {
        width: 75%;
        height: auto;
        margin-bottom: 1em;
      }


      // border: solid 2px white;

      &:visited {
        color: inherit;
      }

      .menu-icon {
        font-size: 1em; // Adjust icon size if necessary
      }

      span {
        cursor: pointer;
        text-decoration: none;
        // display: block; // Makes the span fill the width of the 'a' tag
        display: flex;
        align-items: center;
        color: #6e6c6c;
        border-radius: 0.5em;
        font-size: .80em;
        // width: 100%;
        // height: 1.5em;
        // padding: 0.25em;
        height: 1.5em;
        padding: 0.5em;
        // width: 100%;
        // margin-bottom: 5px;

        &:hover {
          color: #fbfbfb;
          transition-duration: 0.25s;
        }

        .icon-text-container {
          display: inline-flex; // Use inline-flex for the container of the icon and text
          align-items: center; // This will vertically center the icon and text within the container
          gap: 0.7em; // Adjust the gap between the icon and text as needed
        }
      }

      span.current {
        color: #fdefd3;
        background-color: #fdefd342;
      }
    }
  }

  .logos {
    padding: 10.5%;

    .social-media {
      a {
        .icon {
          color: #F7EBD2;
          width: 20px;
          cursor: pointer;
          vertical-align: text-top;
          margin-bottom: 20px;
          transition-duration: color 5s ease;


          &:hover {
            color: #f7ebd2a7;
          }
        }
      }
    }

    p {
      color: #6e6c6c;
      font-size: 75%;
      margin-bottom: 3vh;

      a {
        color: #6e6c6c;

        &:hover {
          color: white;
          transition-duration: 0.75s;
        }
      }
    }

    img {
      width: 90px;
      margin-right: 15px;
      margin-bottom: 15%;

      &:hover {
        transform: translateY(-3px); // Moves the element up slightly
        transition: transform 0.3s ease;
      }
    }
  }
}
