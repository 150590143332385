.bar-chart-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #161616;
  height: 100%;
  width: 100%;
  border-radius: 0.5em;
  // padding-bottom: 0.5em;

  .title {
    padding: 1em;
    color: #8d8d8d;
    font-size: 1em;
  }
}

// .recharts-layer {
//   .recharts-active-bar {
//     min-height: 8px !important;
//     height: 100px;
//   }
// }
